import Vue from 'vue';
import axios from 'axios';

import { LiveTableHandler } from '@/components/editor/liveTableOptions';
import { staticStore } from '@/staticStore';
import cloneDeep from 'lodash/cloneDeep';
import store from '@/store';

export default {
    namespaced: true,
    state: {
        componentTables: {},
        storeTables: {},

    },
    computed: {

    },
    actions: {
        tablePush ({ state, commit, rootState }, data) {
            const liveTableType = staticStore.state.data.liveTableOptions[data.tableType];
            const currentDayChosen = rootState.data.analysisDate.date.format('YYYY-MM-DD');

            if (liveTableType.getOnPeriodChange) {
                if (currentDayChosen !== Vue.moment(data.extraInfo.day).format('YYYY-MM-DD') && rootState.data.analysisDate.period !== data.extraInfo.period) {
                    return;
                }
            }

            if (liveTableType.getOnDayChange) {
                if (currentDayChosen !== Vue.moment(data.extraInfo.day).format('YYYY-MM-DD')) {
                    return;
                }
            }

            if(liveTableType.getOnDayRangeChange){
                const fromToDate = store.getters.fromToHeaderDate;

                if(moment(data.extraInfo.fromDate).isBefore(moment(fromToDate.from).startOf('day')) && moment(data.extraInfo.toDate).isBefore(moment(fromToDate.from).startOf('day'))) return;
                if(moment(data.extraInfo.fromDate).isAfter(moment(fromToDate.to).startOf('day')) && moment(data.extraInfo.toDate).isAfter(moment(fromToDate.to).startOf('day'))) return;

            }

            data.changes.forEach((change) => {
                if (change.changeType !== 'Delete') {
                    LiveTableHandler.changeColourOfRow(change.row, liveTableType.moduleType, rootState.user.bingoColourOverrides);
                }
            });

            if (liveTableType.useStore && Object.values(state.storeTables).some(t => t.tabName === data.tableType)) {
                commit('updateTableInStore', { data, moduleType: liveTableType.moduleType });
            } else {
                commit('updateTableCounter', { data, moduleType: liveTableType.moduleType });
            }
        },
        markNotificationsSeen ({ commit }) {
            axios.get(`${process.env.VUE_APP_STATIC_API}/staticApi/NotificationSeen`);
            commit('markNotificationsSeen');
        },
    },
    mutations: {
        updateStoreTableColours (state) {
            Object.keys(state.storeTables).forEach((tableType) => {
                LiveTableHandler.changeTableColours(state.storeTables[tableType], tableType);
            });
        },
        updateTableInStore (state, data) {
            const success = LiveTableHandler.handlePush(state.storeTables[data.moduleType], data.data.changes, data.moduleType);
            if (data.data.reRequestTable || !success) {
                Vue.set(state.storeTables[data.type], 'reRequestCounter', state.storeTables[data.type].reRequestCounter + 1);
            }
        },
        addTableToStore (state, data) {
            Vue.set(state.storeTables, data.type, data.data);
            Vue.set(state.storeTables[data.type], 'reRequestCounter', 0);
        },
        clearComponentTables (state) {
            state.componentTables = {};
            Vue.set(state, 'tablesBeingDeleted', true);
            if (state.storeTables) {
                const toDelete = [];
                Object.keys(state.storeTables).forEach((moduleLiveTable) => {
                    if (!moduleLiveTable.includes('otification')) {
                        toDelete.push(moduleLiveTable);
                    }
                });
                toDelete.forEach((name) => {
                    Vue.delete(state.storeTables, name);
                });
            }
            Vue.set(state, 'tablesBeingDeleted', false);
        },
        addTableOnScreen (state, data) {
            Vue.set(
                state.componentTables,
                data.id,
                {
                    name: data.type,
                    pushes: [],
                    counter: 0,
                    reRequest: false,
                },
            );
        },
        updateTableCounter (state, data) {
            const toFind = `${data.moduleType}`;

            Object.keys(state.componentTables).forEach((tableId) => {
                if (state.componentTables[tableId].name === toFind) {
                    state.componentTables[tableId].pushes.unshift(cloneDeep(data.data.changes));
                    state.componentTables[tableId].counter += 1;
                    state.componentTables[tableId].reRequest = data.data.reRequestTable;
                }
            });
        },
        markNotificationsSeen (state) {
            state.storeTables.Notifications.data.forEach((o) => {
                o.row.Unseen.values = ['True'];
                o.row.Unseen.backgroundColour = null;
            });
        },
        deleteLastHourNotifications (state, deleteTime) {
            const firstTime = state.storeTables.Notifications.data.findIndex((notification) => notification.row.Time.value < deleteTime);

            state.storeTables.Notifications.data.splice(firstTime);
        },
    },
};
