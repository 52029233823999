import axios from 'axios';
import Vue from 'vue';
import { staticStore } from '@/staticStore';
import store from '@/store';
import { endpoints } from '@/components/inputs/countryInfo';

class LiveTableHandler {
    static async handlePull (moduleType, id, country, date, period, postObject, fromToDate, extraPullInfo = null) {
        const liveTableType = Object.values(staticStore.state.data.liveTableOptions).find((liveTable) => liveTable.moduleType === moduleType);

        const endpointInfo = endpoints.find(x => x.liveTableLocation === liveTableType.location);
        const baseAddress = endpointInfo === undefined ? null : endpointInfo.endpoint;

        const extraParams = LiveTableHandler.getExtraParams(moduleType, country, date, period, extraPullInfo, fromToDate);
        let response;
        if (postObject != null) {
            try {
                response = await axios.post(`${baseAddress || ''}/LiveTable_${moduleType}`, postObject);
            } catch (e) {
                response = await axios.post(`${baseAddress || ''}/LiveTable_${moduleType}`, postObject);
            }
        } else {
            try {
                response = await axios.get(`${baseAddress || ''}/LiveTable_${moduleType}/${store.state.user.userId}${extraParams}`);
            } catch (e) {
                response = await axios.get(`${baseAddress || ''}/LiveTable_${moduleType}/${store.state.user.userId}${extraParams}`);
            }
        }

        let tableData = LiveTableHandler.changeTableColours(response.data, moduleType);

        if (liveTableType.useStore) {
            store.commit('liveTable/addTableToStore', { type: moduleType, data: tableData });
            tableData = null;
        }

        store.commit('liveTable/addTableOnScreen', { id, type: moduleType });

        const pushName = LiveTableHandler.getPushName(moduleType, country, date, period, extraPullInfo);
        store.commit('subscribeToModule', pushName);

        return tableData;
    }

    static async unsubscribe (moduleType, country, date, period, extraPullInfo) {
        const pushName = LiveTableHandler.getPushName(moduleType, country, date, period, extraPullInfo);
        store.commit('unsubscribeToModule', pushName);
    }

    static handlePush (tableData, pushData, moduleType) {
        try {
            const success = pushData.every(change => {
                switch (change.changeType) {
                case 'Add':
                    tableData.data.unshift(change.row);
                    break;
                case 'Edit':
                    if (change.rowIndex < tableData.data.length) {
                        if (tableData.data[change.rowIndex].uniqueId !== change.row.uniqueId) {
                            console.log('ID is wrong');
                            return false;
                        }

                        Object.keys(change.row.row).forEach(header => {
                            Vue.set(tableData.data[change.rowIndex].row, header, change.row.row[header]);
                        });
                    } else {
                        console.log('Length is wrong');
                        return false;
                    }
                    break;
                case 'Delete':
                    tableData.data.splice(change.rowIndex, 1);
                    break;
                case 'DeleteByUniqueId':
                    const rowIndex = tableData.data.findIndex(row => row.uniqueId === change.row.uniqueId);
                    if (rowIndex !== -1) {
                        tableData.data.splice(rowIndex, 1);
                        break;
                    }
                    break;
                default:
                    break;
                }
                return true;
            });
            if (!success) {
                return false;
            }
            const maxEntries = Object.values(staticStore.state.data.liveTableOptions).find(x => x.moduleType === moduleType)?.maxEntriesOnTable;
            if (maxEntries && maxEntries >= 0 && tableData.headerToSort) {
                while (tableData.data.length > maxEntries) {
                    const earliestIndex = this.findEarliestIndex(tableData.data, tableData.headerToSort);
                    tableData.data.splice(earliestIndex, 1);
                }
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    static changeColourOfRow (row, moduleType) {
        if (moduleType === 'BmFeed' || moduleType === 'Notifications') {
            const categoryKey = 'Category';

            const entryName = row.row[categoryKey].values[0];
            const enumName = staticStore.state.data.bingoNamesToEnums[entryName];
            const bingoOverrides = store.state.user.bingoColourOverrides;
            if (bingoOverrides.hasOwnProperty(enumName)) {
                const overrideCol = bingoOverrides[enumName];
                if (!row.row[categoryKey].hasOwnProperty('backgroundColourDefault')) {
                    row.row[categoryKey].backgroundColourDefault = row.row[categoryKey].backgroundColour;
                }
                row.row[categoryKey].backgroundColour = overrideCol;
                row.row[categoryKey].textColour = 'rgb(0,0,0)';
            } else {
                row.row[categoryKey].backgroundColour = row.row[categoryKey].backgroundColourDefault || row.row[categoryKey].backgroundColour;
                row.row[categoryKey].textColour = 'black';
            }
            if (moduleType === 'BmFeed') {
                Object.keys(row.row).forEach((entry) => {
                    row.row[entry].backgroundColour = row.row[categoryKey].backgroundColour;
                    row.row[entry].textColour = row.row[categoryKey].textColour;
                });
            }
        }
        if (moduleType === 'Boa') {
            const categoryKey = 'Type';

            const hasBidOverride = store.state.user.bidColour && store.state.user.bidColour !== '';
            const hasOfferOverride = store.state.user.offerColour && store.state.user.offerColour !== '';

            if (hasBidOverride || hasOfferOverride) {
                if (!row.row[categoryKey].hasOwnProperty('backgroundColourDefault')) {
                    row.row[categoryKey].backgroundColourDefault = row.row[categoryKey].backgroundColour;
                }
            } else row.row[categoryKey].backgroundColour = row.row[categoryKey].backgroundColourDefault || row.row[categoryKey].backgroundColour;

            if (hasBidOverride && row.row[categoryKey].values[0] === 'B') row.row[categoryKey].backgroundColour = store.state.user.bidColour;
            if (hasOfferOverride && row.row[categoryKey].values[0] === 'O') row.row[categoryKey].backgroundColour = store.state.user.offerColour;
        }
    }

    static removePlantColourOverrides (row) {
        if (row.row) {
            Object.keys(row.row).forEach((entry) => {
                if (row.row[entry].isPlant) {
                    if (row.row[entry].values[0] === 'T_LAGA-1') {
                        const b = 1;
                    }
                    Vue.delete(row.row[entry], 'backgroundColour');
                }
            });
        }
    }

    static addPlantColourOverrides (row) {
        if (row.row && store.state.user.plantGroupColourOverride) {
            Object.keys(row.row).forEach((entry) => {
                if (row.row[entry].isPlant) {
                    if (store.state.user.chosenPlantsByGroup[store.state.user.chosenPlantGroupName].includes(row.row[entry].values[0])) {
                        row.row[entry].backgroundColour = store.state.user.chosenPlantGroupColour;
                    }
                }
            });
        }
    }

    static changeTableColours (data, moduleType) {
        if (data.data != null) {
            data.data.forEach((row) => {
                LiveTableHandler.removePlantColourOverrides(row);
            });
        }

        if (moduleType === 'BmFeed' || moduleType === 'Notifications' || moduleType === 'Boa') {
            if (data.data != null) {
                data.data.forEach((row) => {
                    LiveTableHandler.changeColourOfRow(row, moduleType);
                });
            }
        }
        if (data.data != null) {
            data.data.forEach((row) => {
                LiveTableHandler.addPlantColourOverrides(row);
            });
        }
        return data;
    }

    static getPushName (moduleType, country, date, period, extraPullInfo = null) {
        const extraParams = LiveTableHandler.getExtraParams(moduleType, country, date, period, extraPullInfo);
        const pushNameWithExtra = `${moduleType}${extraParams}`;
        const pushName = moduleType === 'Notifications' ? `Notifications${store.state.user.userId}` : pushNameWithExtra;
        return pushName;
    }

    static getExtraParams (moduleType, country, date, period, extraPullInfo = null, fromToDate = null) {
        const liveTableType = Object.values(staticStore.state.data.liveTableOptions).find((liveTable) => liveTable.moduleType === moduleType);

        let extraParams = '';

        if (liveTableType.isEurope) extraParams += `/${country}`;
        if (liveTableType.getOnDayChange) {
            extraParams += `/${date.format('YYYY-MM-DD')}`;
        }

        // Pushes don't include day range so not passed in for that
        if(liveTableType.getOnDayRangeChange && fromToDate){

            const fromDate = moment(fromToDate.from).format('YYYY-MM-DD');
            const toDate = moment(fromToDate.to).format('YYYY-MM-DD');
            extraParams +=  `/${fromDate}/${toDate}`;
        }
        if (liveTableType.getOnPeriodChange) extraParams += `/${period}`;
        if (extraPullInfo) extraParams += `/${extraPullInfo}`;

        return extraParams;
    }

    static findEarliestIndex (data, headerToSort) {
        let earliestIndex = 0;
        let earliestTime = data[0]?.row?.[headerToSort]?.values?.[0] ?? Number.MAX_SAFE_INTEGER;

        data.forEach((item, index) => {
            const currentTime = item?.row?.[headerToSort]?.values?.[0] ?? Number.MAX_SAFE_INTEGER;
            if (currentTime < earliestTime) {
                earliestTime = currentTime;
                earliestIndex = index;
            }
        });
        return earliestIndex;
    }
}

export { LiveTableHandler };
