var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navHolder" }, [
    _vm.menuOpen
      ? _c("div", {
          staticClass: "bg",
          on: {
            click: _vm.closeMenu,
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.closeMenu.apply(null, arguments)
            },
          },
        })
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "nav routerLinks",
        class: {
          open: _vm.menuOpen,
          hiddenOnDesktop: _vm.hideSidenav,
          wide: _vm.headerSelected === "leaderboard",
        },
        on: {
          focus: _vm.openMenu,
          focusout: _vm.closeMenu,
          mouseleave: _vm.closeMenu,
          mouseover: _vm.openMenu,
        },
      },
      [
        _c(
          "div",
          { staticClass: "plantSearch" },
          [
            _vm.$store.state.ui.selectedEnactView === "Enact" &&
            _vm.$store.state.ui.menuOpen
              ? _c("enactSearch2", {
                  attrs: {
                    light: "",
                    categoryOverrides: ["BmPlant", "NonBmPlant"],
                  },
                  on: { select: _vm.goToPlantPage },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "routes niceScroll" },
          _vm._l(_vm.pagesToShow, function (route, link) {
            return _c(
              "div",
              { key: link, staticClass: "linkContainer" },
              [
                route.hasOwnProperty("sectionName")
                  ? _c("div", { staticClass: "header" }, [
                      _c(
                        "div",
                        {
                          staticClass: "link animate",
                          on: {
                            click: function ($event) {
                              return _vm.headerSelect(link)
                            },
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.headerSelect(link)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            [
                              _c("font-awesome-icon", {
                                staticClass: "iconLink",
                                attrs: {
                                  icon: [
                                    route.solid ? "fas" : "fad",
                                    route.icon,
                                  ],
                                  title: route.name,
                                  "aria-label": route.name,
                                },
                              }),
                              _vm.$store.state.ui.menuOpen
                                ? [
                                    _c(
                                      "span",
                                      { staticClass: "textLink header" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(route.sectionName) + " "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "iconHeader icofont-rounded-down",
                                        }),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                      link === _vm.headerSelected
                        ? _c(
                            "div",
                            { staticClass: "subPageHolder" },
                            _vm._l(route.pages, function (page, pageLink) {
                              return _c(
                                "div",
                                { key: pageLink, staticClass: "linkContainer" },
                                [
                                  _c("sideNavPage", {
                                    attrs: {
                                      route: page,
                                      link: pageLink,
                                      iconType: page.solid ? "fas" : "fad",
                                      indent: "",
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ])
                  : _c("sideNavPage", {
                      attrs: {
                        route: route,
                        link: link,
                        iconType: route.solid ? "fas" : "fad",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.headerSelect(null)
                        },
                      },
                    }),
              ],
              1
            )
          }),
          0
        ),
        _vm.$store.state.ui.menuOpen
          ? _c("sideNavBottom", { staticClass: "bottom" })
          : _vm._e(),
      ],
      1
    ),
    !_vm.loggedIn
      ? _c(
          "div",
          {
            staticClass: "nav routerLinks",
            staticStyle: { "margin-bottom": "5px" },
          },
          [
            _c(
              "div",
              {
                staticClass: "routes",
                staticStyle: { "overflow-y": "hidden" },
              },
              _vm._l(_vm.bottomNav, function (nav, key) {
                return _c(
                  "div",
                  {
                    key: key,
                    staticClass: "linkContainer bottomNav",
                    staticStyle: { cursor: "pointer" },
                  },
                  [
                    _c("sideNavPage", {
                      attrs: {
                        route: nav,
                        clickable: "",
                        iconType: nav.iconType,
                        url: nav.url,
                        free: "",
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _vm.showGetInTouch
              ? _c("getInTouch", { on: { close: _vm.toggleGetInTouch } })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }