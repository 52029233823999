<template>
    <div>
        <a v-if="url"
            :href="url"
            target="_blank"
            class="link animate" :class="{ 'hidden-xs': !route.availableOnMobile, 'selected': selectedRoute }"
        >
            <span>
                <font-awesome-icon
                    :icon="[iconType, route.icon]"
                    class="iconLink"
                    :style="{ marginLeft: `${indent ? 20 : 10}px` }"
                    :aria-label="route.name"
                    :title="route.name"
                />
            </span>
        </a>
        <component
            v-else
            :is="getComponent()"
            :to="`/${link == 'current' ? '' : link}`"
            @click="selectRoute"
            class="link animate" :class="{ 'hidden-xs': !route.availableOnMobile, 'disabledRouter': !getAccessLevel(link).hasAccess, 'selected': selectedRoute }"
        >
            <span>
                <font-awesome-icon
                    :icon="[iconType, route.icon]"
                    class="iconLink"
                    :style="{ marginLeft: `${indent ? 20 : 10}px` }"
                    :aria-label="route.name"
                    :title="route.name"
                />
                <template v-if="$store.state.ui.menuOpen">
                    <span class="textLink">{{ route.name }}</span>
                </template>
            </span>
            <disabled-icon
                v-if="!getAccessLevel(link).hasAccess"
                class="disabledIcon"
                :accessLevel="getAccessLevel(link).accessRequired"
                v-tooltip="
                    `Requires ${
                        getAccessLevel(link).accessRequired
                    }`
                "
            />
        </component>
	</div>
</template>

<script>

import Vue from 'vue';
import FlagIcon from 'vue-flag-icon';
import disabledIcon from '@/components/layout/disabledIcon';

Vue.use(FlagIcon);

export default {
    props: {
        link: {
            type: String,
            required: false,
        },
        route: {
            type: Object,
            required: true,
        },
        indent: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
        iconType: {
            type: String,
            default: 'fad',
        },
        externalLink: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            required: false,
        },
        free: {
            type: Boolean,
            required: false,
        },
    },
    components: {
        disabledIcon,
    },
    computed: {
        loggedIn () {
            return !this.$store.state.ui.isFreeUser;
        },
        selectedRoute () {
            const topLevel = this.$route.path.split('/')[1];
            return this.link === topLevel;
        },
    },
    methods: {
        getComponent () {
            return this.getAccessLevel(this.link).hasAccess && !this.clickable ? 'router-link' : 'span';
        },
        getAccessLevel (page) {
            return this.$store.state.ui.pageAccessLevels.hasOwnProperty(page)
                ? this.$store.state.ui.pageAccessLevels[page]
                : {
                    hasAccess: this.loggedIn || this.free,
                    accessRequired: 'Enact Visualise',
                };
        },
        selectRoute () {
            if (this.clickable) {
                this.route.click();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/components/sideNav/sideNav.scss';

</style>
