<template>
  <transition name="fade" mode="out-in">
    <div class="modalWrapper">
      <div v-if="showDarkBackground" class="dark-bg" />
      <div
        class="modal card"
        :class="{
          popoverShadow: showShadow, boxShadow: showDarkBackground, midder: midCard, darker: darkCard, fitContent: fitContent,
        }"
        v-click-outside="onClickOutside"
        v-bind="$attrs">
        <div class="header" :style="{ marginBottom: noTitleSpace ? '-10px' : '10px' }">
          <span class="title bigTitle" :class="[{ smallTitle }, { bigTitle }]">{{title}}</span>
          <span class="close icofont-close" @click.stop="onClickOutside" />
        </div>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        noTitleSpace: {
            type: Boolean,
            required: false,
            default: false,
        },
        showDarkBackground: {
            type: Boolean,
            required: false,
            default: false,
        },
        showShadow: {
            type: Boolean,
            required: false,
            default: false,
        },
        darkCard: {
            type: Boolean,
            required: false,
            default: false,
        },
        midCard: {
            type: Boolean,
            required: false,
            default: false,
        },
        fitContent: {
            type: Boolean,
            required: false,
            default: false,
        },
        smallTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        bigTitle: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
    },
    data () {
        return {

        };
    },
    mounted () {
        document.addEventListener('keyup', this.handleKeyUp);
    },
    destroyed () {
        document.removeEventListener('keyup', this.handleKeyUp);
    },
    computed: {

    },
    methods: {
        onClickOutside (event, el) {
            this.$emit('close');
        },
        handleKeyUp (event) {
            if (event.keyCode === 27) {
                this.$emit('close');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.modalWrapper {
    z-index: 14;

    animation: fadeIn $modal-transition linear;
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }

    .modal {
        height: 100%;
        &.popoverShadow {
            box-shadow: 0 -30px 200px rgba(0, 0, 0, 0.8);
        }
        &.fitContent {
            height: fit-content;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        .title {
            font-weight: 600;
            margin-right: 10px;
            &.bigTitle {
                font-size: 20px;
            }
            &.smallTitle {
                margin-top: 1px;
                font-size: 14px;
                font-weight: 400;
            }
        }
        .close {
            cursor: pointer;
            color: $primary;
            padding-top: 2px;
            font-weight: 600;
            z-index: 1;
        }
    }

    .dark-bg {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $background-modal;
        z-index: -1;
    }
}
</style>
