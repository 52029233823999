/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import moment from 'moment-timezone';
import store from './store';
import { subscribeToOtherTabUserSettingsChange } from './realTimeConnection/broadcastChannel';
import { staticStore, getStaticStore, getPlantStore } from './staticStore';
import { routes } from './routes';
import { endpoints } from './components/inputs/countryInfo';

// import {
//     auth,
// } from '@/services/authentication.js';
import {
    HELLOJSLOGINNAME,
} from '@/services/b2cConfig.js';
import realTimeConnection from '@/realTimeConnection';

import { LiveTableHandler } from '@/components/editor/liveTableOptions';

import { tradingWindowOptions } from '@/components/inputs/tradingWindowOptions';

Vue.use(require('vue-moment'), {
    moment,
});

Vue.moment.tz.setDefault('Europe/London');

import jwt_decode from 'jwt-decode';
import { getMainCountry } from './components/country/countryFunctions';

let firstRoute = true;

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes.map(route => {
        const routeNew = {...route};

        if (route.component !== false) routeNew.component = () => import(/* webpackChunkName: "[request]" */ `./components/views/${route.name}.vue`);

        return routeNew;
    }),
});

router.beforeEach((to, from, next) => {
    document.title = `LCP Enact - ${to.meta.displayName}`;
    next();
});

router.afterEach((to, from, next) => {
    store.commit('setPage', to.name);
});

//This method is called after navigating to a new page, but before it has loaded
router.beforeResolve(async (to, from, next) => {
    if (to.redirectedFrom != null && to.redirectedFrom.includes('#error')) return;
    // 404 page
    if (to.meta.name === undefined && (to.name === undefined || to.name === null)) {
        next('/404');
    }

    // moving to a valid route so lets reset sidebard status etc
    store.commit('resetBeforeRoute');

    //* **** start auth code ******//
    const authResp = await handleAuth(from, to);
    if (authResp) return;
    //* **** end auth code ******//

    // check to see if this route actually requires Auth as per meta
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    // now we check if they are have a state Authentication object
    if (requiresAuth || store.getters['auth/getAuthState']) {
        // now we need to see if the user actually has access to this page / site
        if (store.getters['auth/getAuthAccess']) {
            // ok now we have complete access let update the boolean
            await continueRouting(true);
        } else {
            // user has an auth login but no access on the site as per state so we need to actually check aganst azure
            store.dispatch('auth/hasAccess').then(async (access) => {
                if (access) {
                    // we have checked and they have access
                    await continueRouting(true);
                } else {
                    // Not sure if this should hit ever, if so just send them to log-in (could send to the current free too, but then remove local storage object to prevent loop)
                    await store.dispatch('auth/initLocal');
                    await store.dispatch('auth/signIn');
                }
            }).catch(error => {
                store.commit('accessDeniedModalText', { type: 'licenseNoLogout' });
            });
            // this return false jumps us out while the dispatch does its thing and then we re route
            return false;
        }
    } else {
        // we do not have an auth object so proceed to Enact not logged in
        await continueRouting(false);
    }

    // ok so it must be a require auth page otherwise we would have hit the next() above
    async function continueRouting (loggedIn) {
        store.commit('setDataLoaded', false);
        // now we are going to check th status of the engine.
        try {
            store.commit('setPage', to.name);
            const wasFirstRoute = firstRoute;

            // first time loading after a state refresh i.e first routing methods
            if (firstRoute) {
                await realTimeConnection.setupRealTimeConnection(loggedIn);
                realTimeConnection.install(Vue);

                store.dispatch('updateAnalysisDate', Vue.moment());

                const enactType = localStorage.getItem('enactType');
                if (enactType != null) store.dispatch('selectEnactType', enactType);

                const promiseGets = [getStaticStore(store.state.ui.selectedEnactView)];
                if (!loggedIn) {
                    store.commit('setFreeUser', true);
                    promiseGets.push(getPlantStore(false));

                    promiseGets.push(store.dispatch('getCountryPageAccess'));

                    const acceptedTsAndCs = localStorage.getItem('termsFreeUser');
                    if (!acceptedTsAndCs) {
                        store.commit('receivedGlobalNotification', `Please note that by continuing to use LCP Enact, you agree to our <a href="${process.env.VUE_APP_BASE_URL}/terms" target="_blank">Terms and Conditions</a>.`);
                        localStorage.setItem('termsFreeUser', true)
                    }
                } else {
                    Vue.$gtag.config({ user_id: store.state.user.userId })

                    realTimeConnection.addUserBrowserSubscriptions(store.state.user.userId);

                    promiseGets.push(GetUserSettingsAndPageAccess());
                    promiseGets.push(getPlantStore(store.state.ui.selectedEnactView === 'Enact'));

                    store.commit('setFreeUser', false);

                    if (store.state.ui.selectedEnactView === 'Enact') {
                        endpoints.forEach(endpoint => {
                            if (!endpoint.getEuropePlant) return;

                            promiseGets.push(GetPlants(endpoint));
                        })
                    }

                    subscribeToOtherTabUserSettingsChange();
                }

                await Promise.all(promiseGets);

                if (loggedIn)
                {
                    // These are only null if the backend determines you don't have access
                    if (staticStore.state.data.dynamicSeriesNew == null && staticStore.state.data.bingoEnums == null) {
                        store.commit('accessDeniedModalText', { type: 'licenseNoLogout' });
                    }

                    LiveTableHandler.handlePull(staticStore.state.data.liveTableOptions.notifications.moduleType, staticStore.state.data.liveTableOptions.notifications.moduleType);

                    const currentUserCountry = getMainCountry(store.state.user.country)

                    if (!currentUserCountry.hasEuropeAccess) {
                        const countryWithEuropeAccess = staticStore.state.data.countries.find(el => el.hasEuropeAccess);
                        if (countryWithEuropeAccess !== undefined) {
                            store.dispatch('setDefaultCountryAndPageAccess', countryWithEuropeAccess.id);
                        };
                    }
                }

                store.commit('ranFirstRouteCode');

                firstRoute = false;

                if (loggedIn && to.path == '/') {
                    next('/dashboard');
                    return;
                }
            }

            const page = to.path.split('/')[1].toLowerCase();
            if (store.state.ui.pageAccessLevels.hasOwnProperty(page) && !store.state.ui.pageAccessLevels[page].hasAccess) {
                next('/');
                return;
            }

            if (wasFirstRoute ||tradingWindowOptions.find(x => x.id === to.name)) {
                await store.dispatch('user/getAndUpdateCurrentPeriod', false);
            };

            // store.commit('setPage', to.name);
            // subscribe to the new page for signalR
            store.commit('subscribeToPage', to.name);
            // unsub from the previous page
            await realTimeConnection.handleActiveSessions(from.name);
            // store.commit('unsubscribeToPage', from.name);
            store.commit('closeSlideOutMenu');

            if (from.name === 'NotificationsPage') store.dispatch('liveTable/markNotificationsSeen');
            // await store.dispatch('removeAllPages');
            store.commit('liveTable/clearComponentTables');

            store.commit('overwriteDashboardDateChanged', false);

            //Clearing boa data when moving between dashboard pages is managed in Dashboard.vue
            const movingBetweenDashboards = from.name === 'dashboard' & to.name ==='dashboard'
            if(!movingBetweenDashboards) {
                await store.dispatch('clearPageData', from.name);
            }

            switch (to.name) {
            case 'boa':
            case 'boaComparer':
                next();
                break;
            default:
                store.commit('setDataLoaded', true);
                next();
                break;
            }
        }
        // catch any errors? Should we be going to the next?
        catch (e) {
            console.log(e);
            store.commit('setPage', to.name);
            next();
        }
    }
});

function GetPlants (endpoint) {
    axios.get(`${endpoint.endpoint}/Plant`).then((response) => {
        staticStore.state.data.plantsByEndpoint[endpoint.id] = response.data.plantList;
    }).catch(e => {});
}

async function GetUserSettingsAndPageAccess () {
    await store.dispatch('user/getUserSettings', store.state.user.userId).then(() => {
        store.dispatch('getCountryPageAccess');
    }).catch(e => console.log(`Please contact Enact Helpdesk - failed to get User Settings (${e})`));
}

async function handleAuth (from, to) {
    if (to.name === 'login' || to.name === 'logout') await store.dispatch('auth/initLocal');
    if (to.name === 'login') {
        // already logged in ?  if so go to dashboard else initiate sign in protocol
        const authOnline = await store.dispatch('auth/online', HELLOJSLOGINNAME);
        if (authOnline) {
            if (from.path !== '/') router.push('/');
        } else {
            await store.dispatch('auth/signIn');
        }
        return true;
    }

    if (to.name === 'logout') {
        await store.dispatch('auth/signOut');
    }

    // look to see if we have a local auth session cookie
    let authResponse = localStorage.getItem('hello');

    // check to see if we have the auth details in STATE
    if (!store.getters['auth/getAuthState']) {
        // we dont have a state auth object we check for a local object
        const authResponseValid = authResponse && authResponse !== '{}';
        if (authResponseValid) {
            // we have a local object
            authResponse = JSON.parse(authResponse).b2clogin;

            const authResponseHasError = authResponse.error && authResponse.error.code === 'access_denied';

            if (authResponseHasError) {
                removeAuthState();
                router.push('/');
                return true;
            }

            const decoded = jwt_decode(authResponse.access_token);
            // check to see if the session is still valid
            if (decoded && Vue.moment.unix(authResponse.expires) > Vue.moment() && Vue.moment.unix(decoded.exp) > Vue.moment()) {
                // console.log(authResponse.expires);
                store.dispatch('auth/setAuthState', authResponse);
                // enact specific code
                store.commit('user/setUserFromToken', decoded);
                axios.defaults.headers.common.Authorization = `Bearer ${authResponse.access_token}`;
                realTimeConnection.setBearerToken(authResponse.access_token);

                const maybeLocalIp = new Promise((r) => {
                    const w = window;
                    const a = new (w.RTCPeerConnection || w.mozRTCPeerConnection || w.webkitRTCPeerConnection)({
                        iceServers: [],
                    });
                    const b = () => {};
                    a.createDataChannel('');
                    a.createOffer((c) => a.setLocalDescription(c, b, b), b);
                    a.onicecandidate = (c) => {
                        try {
                            c.candidate.candidate.match(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g).forEach(r);
                        } catch (e) {}
                    };
                });
                maybeLocalIp.then((ip) => axios.defaults.headers.common['X-Enact-LocalIp'] = ip);

                // enact specific code end
                if (authResponse.state === 'lcp') {
                    store.commit('auth/setIsLcpLogin', true);
                    store.commit('auth/setIsLocalLogin', false);
                } else if (authResponse.state === 'local') {
                    store.commit('auth/setIsLcpLogin', false);
                    store.commit('auth/setIsLocalLogin', true);
                }
            } else {
                removeAuthState();
                router.push('/login');
                return true;
            }
        } else if (authResponse && authResponse === '{}') {
            removeAuthState();
            return true;
        }
    }
    return false;
}

function removeAuthState() {
    localStorage.removeItem('hello');
    store.dispatch('auth/setAuthState', null);
}

export default router;
