import Vue from 'vue';
// util functions
import moment from 'moment-timezone';
import axios from 'axios';
import VueGtag from 'vue-gtag';

import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import VueUUID from 'vue-uuid';

import PrettyCheckbox from 'pretty-checkbox-vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import {
//     faUsers as farUsers, faPiggyBank, faDonate, faCoin, faUniversity, faPoundSign, faCheck, faTimes,
// } from '@fortawesome/pro-regular-svg-icons';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
    faBell, faCalculatorSimple, faTimes, faSearch, faExternalLink, faChevronRight, faChevronLeft, faArrowLeft as faArrowLeftSolid, faArrowRight, faChevronDown, faChevronUp, faSlidersSimple, faDownload, faUpload,
    faSave, faCommentDots, faQuestion, faEnvelope, faCheckCircle, faTimesCircle, faLock, faLockOpen, faBan, faShareAlt, faBolt, faUpRightAndDownLeftFromCenter, faXmark,
} from '@fortawesome/pro-solid-svg-icons';
// import {
//     faBullseye, faDesktop, faMailBulk, faAnalytics, faChess, faQuestion, faInfo, faSun, faMoon, faSignOutAlt,
// } from '@fortawesome/pro-light-svg-icons';
import {
    faColumns, faLayerGroup, faEuroSign, faArrowAltSquareUp, faTrophy, faTh, faThList, faTable, faHistory, faCogs, faWrench, faSunCloud, faWindTurbine,
    faGlobeEurope, faMapMarkedAlt, faMapPin, faIndustry, faEngineWarning, faList, faBrakeWarning, faFileSignature, faRss, faInfoCircle, faBellOn, faGlobe,
    faLightbulbOn, faMedal, faNewspaper, faSearch as fadSearch, faHome, faUser, faChartLine, faQuestionCircle, faFire, faAnalytics, faArrowLeft, faDatabase, faBrowser, faChartBar, faChartScatter,
    faCalculator,

} from '@fortawesome/pro-duotone-svg-icons';

import { faCloudArrowUp, faFile } from '@fortawesome/pro-regular-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

import vueAwesomeCountdown from 'vue-awesome-countdown';
import VTooltip from 'v-tooltip';
import Snotify from 'vue-snotify';
import { staticStore } from './staticStore';
import store from './store';
import router from './router';
import App from './App';
import 'vue-snotify/styles/dark.css';

import '@/assets/icofont/icofont.min.css';
import '@/assets/css/bootstrap-grid.css';
import 'reset-css';
import 'animate.css';

library.add(
    faBell,
    faXmark,
    faCalculator,
    faCalculatorSimple,
    faCogs,
    faCommentDots,
    faTimes,
    faColumns,
    faLayerGroup,
    faEuroSign,
    faShareAlt,
    faBolt,
    faExternalLink,
    faQuestion,
    faChevronDown,
    faChevronUp,
    faArrowAltSquareUp,
    faTrophy,
    faTh,
    faThList,
    faTable,
    faHistory,
    faSearch,
    faGlobeEurope,
    faSunCloud,
    faWindTurbine,
    faHome,
    faUser,
    faChartLine,
    faChevronLeft,
    faChevronRight,
    faTimes,
    faTimesCircle,
    faMapMarkedAlt,
    faMapPin,
    faIndustry,
    faEngineWarning,
    faBrakeWarning,
    faList,
    faFileSignature,
    faRss,
    faInfoCircle,
    faBellOn,
    faLightbulbOn,
    faMedal,
    faGlobe,
    faSave,
    faWrench,
    faCheckCircle,
    faNewspaper,
    faSearch,
    fadSearch,
    faQuestionCircle,
    faFire,
    faAnalytics,
    faArrowLeft,
    faArrowRight,
    faArrowLeftSolid,
    faDatabase,
    faEnvelope,
    faTwitter,
    faLinkedin,
    faBrowser,
    faLock,
    faLockOpen,
    faChartBar,
    faChartScatter,
    faBan,
    faSlidersSimple,
    faDownload,
    faUpRightAndDownLeftFromCenter,
    faCloudArrowUp,
    faFile,
    faUpload,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

// register globally
Vue.component('multiselect', Multiselect);
Vue.use(VueUUID);
Vue.use(PrettyCheckbox);
Vue.use(vueAwesomeCountdown, 'countdown'); // Component name, `countdown` and `vac` by default

Vue.prototype.$axios = axios;

Vue.use(VueGtag, {
    config: { id: 'G-NQF3306KG5' },
}, router);

Vue.use(require('vue-moment'), {
    moment,
});

moment.tz.setDefault('Europe/London');

Vue.use(VTooltip);
const snotifyGlobalOptions = {
    global: {
        // preventDuplicates: true,
    // oneAtTime: true
    },
    toast: {
        timeout: 8000,
    },
};
// 1. Import Snotify
// 2. Use Snotify
// You can pass {config, options} as second argument. See the next example or setConfig in [API] section
Vue.use(Snotify, snotifyGlobalOptions);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    staticStore,
    render: (h) => h(App),
}).$mount('#app');
